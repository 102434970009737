import React from "react";
import styled from "styled-components";

const Feedback = () => {
  return (
    <Container>
      <HeroSection>
        <h1>Customer Feedback</h1>
        <p>Help us serve you better</p>
      </HeroSection>

      <ContentSection>
        <InfoBox>
          <h2>We Value Your Voice</h2>
          <p>
            We value the quality of our interactions and aim to always meet your
            expectations. However, we understand that there may be instances
            where we fall short. Your feedback is crucial to our continuous
            improvement culture.
          </p>
        </InfoBox>

        <ConcernsSection>
          <h2>What Can You Give Feedback About?</h2>
          <ConcernsList>
            <ConcernItem>
              <IconWrapper>📊</IconWrapper>
              <span>Your account management</span>
            </ConcernItem>
            <ConcernItem>
              <IconWrapper>👥</IconWrapper>
              <span>Staff interactions and service</span>
            </ConcernItem>
            <ConcernItem>
              <IconWrapper>🔒</IconWrapper>
              <span>Personal information handling</span>
            </ConcernItem>
            <ConcernItem>
              <IconWrapper>📝</IconWrapper>
              <span>Any other concerns</span>
            </ConcernItem>
          </ConcernsList>
        </ConcernsSection>

        <ProcessSection>
          <h2>Our Feedback Process</h2>
          <StepGrid>
            <Step>
              <StepNumber>1</StepNumber>
              <StepContent>
                <h3>Initial Contact</h3>
                <p>
                  Promptly raise your concerns with your Account Manager's Team
                  Leader for immediate attention.
                </p>
              </StepContent>
            </Step>

            <Step>
              <StepNumber>2</StepNumber>
              <StepContent>
                <h3>Customer Care Team</h3>
                <p>
                  If unresolved, our dedicated Customer Care Team is available
                  at 1300 930 070 – Option 3.
                </p>
              </StepContent>
            </Step>

            <Step>
              <StepNumber>3</StepNumber>
              <StepContent>
                <h3>External Resolution</h3>
                <p>
                  For further escalation, you can contact AFCA (Australian
                  Financial Complaints Authority) for an independent assessment.
                </p>
              </StepContent>
            </Step>
          </StepGrid>
        </ProcessSection>

        <CommitmentBox>
          <h2>Our Commitment to You</h2>
          <CommitmentList>
            <li>Clear and transparent communication throughout the process</li>
            <li>Thorough investigation of all concerns</li>
            <li>Fair and timely resolution</li>
            <li>Support throughout the complaint process</li>
            <li>Dedication to exceptional customer service</li>
          </CommitmentList>
        </CommitmentBox>

        <ActionSection>
          <h2>Ready to Share Your Feedback?</h2>
          <p>
            Your feedback helps us improve our services and better serve our
            customers.
          </p>
          <FeedbackButton href="#" target="_blank" rel="noopener noreferrer">
            Submit Feedback
          </FeedbackButton>
        </ActionSection>
      </ContentSection>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const HeroSection = styled.div`
  background-image: url("../assets/feedback-hero.jpg"); // 建议添加一张展示客户服务或团队协作的背景图
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 20px;
  margin-bottom: 50px;
  border-radius: 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }

  h1,
  p {
    position: relative;
    z-index: 1;
  }

  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.5rem;
    opacity: 0.9;
  }
`;

const ContentSection = styled.div`
  padding: 40px 0;
`;

const InfoBox = styled.div`
  background-color: #f5f5f5;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 40px;

  h2 {
    color: #76b900;
    margin-bottom: 20px;
  }

  p {
    line-height: 1.6;
  }
`;

const ProcessSection = styled.div`
  margin: 60px 0;

  h2 {
    text-align: center;
    color: #76b900;
    margin-bottom: 40px;
  }
`;

const StepGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 30px;
`;

const Step = styled.div`
  display: flex;
  align-items: flex-start;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const StepNumber = styled.div`
  background: #76b900;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 20px;
  flex-shrink: 0;
`;

const StepContent = styled.div`
  h3 {
    color: #333;
    margin-bottom: 10px;
  }

  p {
    color: #666;
    line-height: 1.5;
  }
`;

const ActionSection = styled.div`
  text-align: center;
  margin: 60px 0;
  padding: 40px;
  background: #f9f9f9;
  border-radius: 10px;

  h2 {
    color: #76b900;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 30px;
  }
`;

const FeedbackButton = styled.a`
  display: inline-block;
  background: #76b900;
  color: white;
  padding: 15px 40px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  transition: background 0.3s ease;

  &:hover {
    background: #648e00;
  }
`;

const ConcernsSection = styled.div`
  margin: 40px 0;
  padding: 30px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  h2 {
    color: #76b900;
    margin-bottom: 25px;
    text-align: center;
  }
`;

const ConcernsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
`;

const ConcernItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  background: #f9f9f9;
  border-radius: 8px;

  span {
    margin-left: 15px;
  }
`;

const IconWrapper = styled.div`
  font-size: 24px;
  min-width: 40px;
`;

const CommitmentBox = styled.div`
  margin: 40px 0;
  padding: 30px;
  background: #f5f5f5;
  border-radius: 10px;
  border-left: 5px solid #76b900;

  h2 {
    color: #76b900;
    margin-bottom: 20px;
  }
`;

const CommitmentList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    margin: 15px 0;
    padding-left: 35px;
    position: relative;

    &:before {
      content: "✓";
      color: #76b900;
      position: absolute;
      left: 0;
      width: 20px;
      height: 20px;
      border: 2px solid #76b900;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 1;
    }
  }
`;

export default Feedback;
