import styled from "styled-components";
import Container from "../components/common/Container";
import Button from "../components/common/Button";

const Careers = () => {
  return (
    <Container>
      <CareersSection>
        <h1>Be Part of Our Journey</h1>

        <h2>Our Mission</h2>
        <p>
          Our mission is to provide better financial outcomes to our customers
          through flexible payment solutions. We are dedicated to our core
          values of excellence, collaboration, being customer focused,
          accountability and continuous improvement and strive to make a
          positive and meaningful difference to the collections industry.
        </p>

        <h2>About our team</h2>
        <p>
          Our team at Freedom Credit is a dynamic and dedicated group of
          individuals who share a common passion for our core values of
          excellence, collaboration, customer focused, accountability and
          continuous improvement. We believe that the synergy of diverse talents
          is the key to solving complex problems and achieving remarkable
          results for our clients and customers. At Freedom Credit, we proudly
          embrace the strength of diversity in our team. We believe that
          bringing together individuals from various backgrounds, from various
          backgrounds, cultures, and perspectives enriches our work environment
          and enhances our ability to tackle complex challenges.
        </p>

        <h2>Join Our Team</h2>
        <p>
          At Freedom Credit, we believe in the power of exceptional talent. We
          are constantly looking for passionate and motivated individuals to
          join any of our three offices, who share our commitment to our core
          values. If you are ready to embark on a rewarding journey and make a
          meaningful impact, we invite you to explore career opportunities with
          us.
        </p>

        <BenefitsSection>
          <h2>Why Work With Us</h2>
          <BenefitsGrid>
            <BenefitCard>
              <h3>Entry level opportunities </h3>
              <p>no previous Collections experience required!</p>
            </BenefitCard>
            <BenefitCard>
              <h3>Competitive Compensation</h3>
              <p>
                Above market base salary on offer plus uncapped monthly bonuses
              </p>
            </BenefitCard>
            <BenefitCard>
              <h3>Work life balance</h3>
              <p>
                flexible work arrangements on offer post probation period as you
                gain experience
              </p>
            </BenefitCard>
            {/* Add more benefits */}
          </BenefitsGrid>
        </BenefitsSection>

        <JobListings>
          <h2>Current Openings</h2>

          <JobCard>
            <h3>Data Analyst</h3>
            <p>Full-time • Remote</p>
            <p>
              Join our engineering team to build innovative financial solutions.
            </p>
            <Button>Apply Now</Button>
          </JobCard>

          <JobCard>
            <h3>Financial Analyst</h3>
            <p>Full-time • New York</p>
            <p>Help our clients make informed financial decisions.</p>
            <Button>Apply Now</Button>
          </JobCard>

          {/* Add more job listings */}
        </JobListings>
      </CareersSection>
    </Container>
  );
};

const CareersSection = styled.div`
  padding: 4rem 0;
  background: #ffffff;
  color: #000000;

  h1 {
    text-align: center;
    margin-bottom: 2rem;
    color: #76b900;
    font-weight: 700;
  }

  h2 {
    color: #76b900;
    margin-top: 2rem;
    font-weight: 600;
  }
`;

const JobListings = styled.div`
  display: grid;
  gap: 2rem;
  margin: 3rem 0;
`;

const JobCard = styled.div`
  padding: 2rem;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  transition: all 0.3s ease;

  h3 {
    margin-bottom: 1rem;
    color: #76b900;
  }

  button {
    margin-top: 1rem;
    background: #76b900;
    color: #ffffff;
    font-weight: 600;
    border: none;

    &:hover {
      background: #8ed100;
      transform: translateY(-2px);
    }
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(118, 185, 0, 0.1);
  }
`;

const BenefitsSection = styled.section`
  margin-top: 4rem;
`;

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const BenefitCard = styled.div`
  padding: 2rem;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  transition: all 0.3s ease;

  h3 {
    color: #76b900;
    margin-bottom: 1rem;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(118, 185, 0, 0.1);
  }
`;

export default Careers;
