import React from "react";
import styled from "styled-components";

const FinancialHardship = () => {
  return (
    <Container>
      <HeroSection>
        <h1>Financial Hardship Assistance</h1>
        <p>We're here to support you through difficult times</p>
      </HeroSection>

      <ContentSection>
        <InfoBox>
          <h2>We Understand</h2>
          <p>
            We all face unexpected circumstances that affect our financial
            position from time to time. At Complete Credit Solutions ("CCS") we
            focus on helping you meet your obligations, within your current
            financial means. We have numerous options available to assist our
            customers in reaching financial recovery.
          </p>
          <p>
            Our staff will assist you with respect and dignity, and work with
            you to achieve the best outcome for you in the immediate and long
            term.
          </p>
        </InfoBox>

        <ProcessSection>
          <h2>How We Can Help</h2>
          <StepGrid>
            <Step>
              <StepNumber>1</StepNumber>
              <StepContent>
                <h3>Submit Your Application</h3>
                <p>
                  Complete our Financial Hardship Assistance Form and provide
                  supporting documentation.
                </p>
              </StepContent>
            </Step>

            <Step>
              <StepNumber>2</StepNumber>
              <StepContent>
                <h3>Assessment</h3>
                <p>
                  Our dedicated team will review your situation and assess the
                  best options for your circumstances.
                </p>
              </StepContent>
            </Step>

            <Step>
              <StepNumber>3</StepNumber>
              <StepContent>
                <h3>Tailored Solution</h3>
                <p>
                  We'll work with you to develop a manageable plan that suits
                  your current financial situation.
                </p>
              </StepContent>
            </Step>
          </StepGrid>
        </ProcessSection>

        <SupportOptionsBox>
          <h2>Available Support Options</h2>
          <OptionsList>
            <li>Payment arrangements that match your capacity to pay</li>
            <li>Temporary payment reductions</li>
            <li>Extension of loan term to reduce payments</li>
            <li>Postponement of payments in certain circumstances</li>
            <li>Financial counseling referral services</li>
          </OptionsList>
        </SupportOptionsBox>

        <DocumentSection>
          <h2>Required Documentation</h2>
          <p>To help us assess your situation, please provide:</p>
          <DocumentGrid>
            <DocumentItem>
              <IconWrapper>📄</IconWrapper>
              <span>Income statements or payslips</span>
            </DocumentItem>
            <DocumentItem>
              <IconWrapper>📊</IconWrapper>
              <span>Bank statements</span>
            </DocumentItem>
            <DocumentItem>
              <IconWrapper>📝</IconWrapper>
              <span>List of assets and liabilities</span>
            </DocumentItem>
            <DocumentItem>
              <IconWrapper>📋</IconWrapper>
              <span>Evidence of hardship (medical certificates, etc.)</span>
            </DocumentItem>
          </DocumentGrid>
        </DocumentSection>

        <TipBox>
          <h3>Helpful Tip</h3>
          <p>
            If you complete this process on your phone, you can take photos of
            supporting documents, or take screenshots of anything you have
            access to on your phone and attach those photos to expedite the
            process.
          </p>
        </TipBox>

        <ActionSection>
          <h2>Ready to Apply?</h2>
          <p>
            Complete our Financial Hardship Assistance Form to begin the
            process. We're here to help you through this.
          </p>
          <ActionButton href="#" target="_blank" rel="noopener noreferrer">
            Apply for Assistance
          </ActionButton>
        </ActionSection>
      </ContentSection>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const HeroSection = styled.div`
  background-image: url("../assets/financial-hardship-hero.jpg"); // 建议使用一张表达支持和帮助的温暖图片
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 20px;
  margin-bottom: 50px;
  border-radius: 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }

  h1,
  p {
    position: relative;
    z-index: 1;
  }

  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.5rem;
    opacity: 0.9;
  }
`;

const ContentSection = styled.div`
  padding: 40px 0;
`;

const InfoBox = styled.div`
  background-color: #f5f5f5;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 40px;

  h2 {
    color: #76b900;
    margin-bottom: 20px;
  }

  p {
    line-height: 1.6;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ProcessSection = styled.div`
  margin: 60px 0;

  h2 {
    text-align: center;
    color: #76b900;
    margin-bottom: 40px;
  }
`;

const StepGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 30px;
`;

const Step = styled.div`
  display: flex;
  align-items: flex-start;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const StepNumber = styled.div`
  background: #76b900;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 20px;
  flex-shrink: 0;
`;

const StepContent = styled.div`
  h3 {
    color: #333;
    margin-bottom: 10px;
  }

  p {
    color: #666;
    line-height: 1.5;
  }
`;

const SupportOptionsBox = styled.div`
  margin: 40px 0;
  padding: 30px;
  background: #f5f5f5;
  border-radius: 10px;
  border-left: 5px solid #76b900;

  h2 {
    color: #76b900;
    margin-bottom: 20px;
  }
`;

const OptionsList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    margin: 15px 0;
    padding-left: 35px;
    position: relative;

    &:before {
      content: "✓";
      color: #76b900;
      position: absolute;
      left: 0;
      width: 20px;
      height: 20px;
      border: 2px solid #76b900;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 1;
    }
  }
`;

const DocumentSection = styled.div`
  margin: 40px 0;
  padding: 30px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  h2 {
    color: #76b900;
    margin-bottom: 15px;
  }

  > p {
    margin-bottom: 25px;
  }
`;

const DocumentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
`;

const DocumentItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  background: #f9f9f9;
  border-radius: 8px;

  span {
    margin-left: 15px;
  }
`;

const IconWrapper = styled.div`
  font-size: 24px;
  min-width: 40px;
`;

const TipBox = styled.div`
  margin: 40px 0;
  padding: 20px;
  background: #fff3cd;
  border-radius: 10px;
  border-left: 5px solid #ffc107;

  h3 {
    color: #856404;
    margin-bottom: 10px;
  }

  p {
    color: #856404;
    line-height: 1.5;
  }
`;

const ActionSection = styled.div`
  text-align: center;
  margin: 60px 0;
  padding: 40px;
  background: #f9f9f9;
  border-radius: 10px;

  h2 {
    color: #76b900;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 30px;
  }
`;

const ActionButton = styled.a`
  display: inline-block;
  background: #76b900;
  color: white;
  padding: 15px 40px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  transition: background 0.3s ease;

  &:hover {
    background: #648e00;
  }
`;

export default FinancialHardship;
