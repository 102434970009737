import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <Container>
      <HeroSection>
        <HeroContent>
          <h1>Freedom Credit</h1>
          <p>Your Partner in Financial Solutions</p>
          <HeroButtons>
            <PrimaryButton to="/contact">Get Started</PrimaryButton>
            <SecondaryButton to="/financial-hardship">
              Need Support?
            </SecondaryButton>
          </HeroButtons>
        </HeroContent>
      </HeroSection>

      <ServicesSection>
        <SectionTitle>How We Can Help</SectionTitle>
        <ServiceGrid>
          <ServiceCard>
            <IconWrapper>💰</IconWrapper>
            <h3>Flexible Payment Plans</h3>
            <p>Customized solutions that work for your financial situation</p>
          </ServiceCard>
          <ServiceCard>
            <IconWrapper>🤝</IconWrapper>
            <h3>Financial Support</h3>
            <p>Expert guidance and assistance when you need it most</p>
          </ServiceCard>
          <ServiceCard>
            <IconWrapper>📋</IconWrapper>
            <h3>Account Management</h3>
            <p>Easy-to-use tools to manage your account and payments</p>
          </ServiceCard>
        </ServiceGrid>
      </ServicesSection>

      <FeaturesSection>
        <SectionTitle>Why Choose Us</SectionTitle>
        <FeatureGrid>
          <FeatureItem>
            <FeatureIcon>✨</FeatureIcon>
            <h3>Transparent Process</h3>
            <p>Clear communication and no hidden fees</p>
          </FeatureItem>
          <FeatureItem>
            <FeatureIcon>🛡️</FeatureIcon>
            <h3>Secure & Reliable</h3>
            <p>Your data and privacy are our top priority</p>
          </FeatureItem>
          <FeatureItem>
            <FeatureIcon>⚡</FeatureIcon>
            <h3>Quick Response</h3>
            <p>Fast processing and dedicated support</p>
          </FeatureItem>
          <FeatureItem>
            <FeatureIcon>❤️</FeatureIcon>
            <h3>Customer First</h3>
            <p>Focused on your financial wellbeing</p>
          </FeatureItem>
        </FeatureGrid>
      </FeaturesSection>

      <SupportSection>
        <SupportContent>
          <h2>Need Additional Support?</h2>
          <p>
            We understand that financial circumstances can change. Our team is
            here to help you find the right solution.
          </p>
          <SupportOptions>
            <SupportCard to="/financial-hardship">
              <h3>Financial Hardship</h3>
              <p>Get help with managing your payments</p>
            </SupportCard>
            <SupportCard to="/authorised-representative">
              <h3>Professional Support</h3>
              <p>Connect with financial counsellors</p>
            </SupportCard>
            <SupportCard to="/feedback">
              <h3>Feedback</h3>
              <p>Share your thoughts with us</p>
            </SupportCard>
          </SupportOptions>
        </SupportContent>
      </SupportSection>

      <CTASection>
        <CTAContent>
          <h2>Ready to Take Control?</h2>
          <p>Let's work together to find the right solution for you</p>
          <PrimaryButton to="/contact">Contact Us Today</PrimaryButton>
        </CTAContent>
      </CTASection>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const HeroSection = styled.section`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem 1rem;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../assets/home-hero.jpg") center/cover;
  color: white;
  border-radius: 10px;
  margin: 20px 0;
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;

  h1 {
    font-size: clamp(2.5rem, 5vw, 4rem);
    margin-bottom: 1.5rem;
  }

  p {
    font-size: clamp(1.2rem, 2.5vw, 1.8rem);
    margin-bottom: 2.5rem;
    opacity: 0.9;
  }
`;

const HeroButtons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
`;

const ButtonBase = styled(Link)`
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 30px;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 1.1rem;
`;

const PrimaryButton = styled(ButtonBase)`
  background: #76b900;
  color: white;

  &:hover {
    background: #648e00;
  }
`;

const SecondaryButton = styled(ButtonBase)`
  background: transparent;
  color: white;
  border: 2px solid white;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const SectionTitle = styled.h2`
  text-align: center;
  font-size: clamp(2rem, 4vw, 2.5rem);
  color: #76b900;
  margin-bottom: 3rem;
`;

const ServicesSection = styled.section`
  padding: 5rem 0;
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const ServiceCard = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  h3 {
    color: #333;
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  p {
    color: #666;
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
`;

const IconWrapper = styled.div`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const CardLink = styled(Link)`
  color: #76b900;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

const FeaturesSection = styled.section`
  padding: 5rem 0;
  background: #f9f9f9;
  margin: 2rem -20px;
  padding: 5rem 20px;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const FeatureItem = styled.div`
  text-align: center;
  padding: 2rem;

  h3 {
    color: #333;
    font-size: 1.3rem;
    margin: 1rem 0;
  }

  p {
    color: #666;
    line-height: 1.6;
  }
`;

const FeatureIcon = styled.div`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const SupportSection = styled.section`
  padding: 5rem 0;
`;

const SupportContent = styled.div`
  text-align: center;
  margin-bottom: 3rem;

  h2 {
    color: #76b900;
    font-size: clamp(1.8rem, 4vw, 2.3rem);
    margin-bottom: 1rem;
  }

  p {
    color: #666;
    max-width: 800px;
    margin: 0 auto 3rem;
    line-height: 1.6;
  }
`;

const SupportOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const SupportCard = styled(Link)`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  h3 {
    color: #76b900;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }

  p {
    color: #666;
    font-size: 1rem;
  }
`;

const CTASection = styled.section`
  background: #76b900;
  margin: 2rem -20px;
  padding: 5rem 20px;
  color: white;
`;

const CTAContent = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;

  h2 {
    font-size: clamp(2rem, 4vw, 2.5rem);
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    opacity: 0.9;
  }

  ${PrimaryButton} {
    background: white;
    color: #76b900;

    &:hover {
      background: rgba(255, 255, 255, 0.9);
    }
  }
`;

export default HomePage;
