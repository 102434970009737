import styled from 'styled-components';

const Button = styled.button`
  padding: ${props => props.small ? '0.5rem 1rem' : '1rem 2rem'};
  background-color: ${props => props.secondary ? props.theme.colors.secondary : props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: ${props => props.small ? '0.9rem' : '1rem'};
  transition: transform 0.2s, background-color 0.2s;

  &:hover {
    transform: translateY(-2px);
    background-color: ${props => props.secondary ? 
      props.theme.colors.secondary + 'dd' : 
      props.theme.colors.primary + 'dd'};
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export default Button;
