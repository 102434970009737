export const theme = {
  colors: {
    primary: '#0056b3',
    secondary: '#4a90e2',
    text: '#333333',
    background: '#ffffff',
    accent: '#28a745',
  },
  fonts: {
    primary: "'Open Sans', sans-serif",
    heading: "'Montserrat', sans-serif",
  },
  breakpoints: {
    mobile: '320px',
    tablet: '768px',
    desktop: '1024px',
    large: '1200px'
  },
  spacing: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '2rem',
    xl: '4rem'
  },
  container: {
    padding: {
      mobile: '1rem',
      tablet: '2rem',
      desktop: '4rem'
    }
  }
};
