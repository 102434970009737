import React, { useState } from "react";
import styled from "styled-components";
import Container from "../components/common/Container";

const categories = {
  "Financial Assistance": [
    {
      name: "MoneySmart",
      description:
        "Free financial guidance and tools provided by ASIC to help you make smart financial decisions. Offers advice on budgeting, saving, and investing.",
      link: "https://moneysmart.gov.au",
      logo: require("../assets/logos/moneysmart.jpg"),
    },
    {
      name: "Financial Rights",
      description:
        "Provides free financial legal advice to Australians. Focuses on debt, insurance, banking, and financial complaints.",
      link: "https://financialrights.org.au",
      logo: require("../assets/logos/Financial Rights.png"),
    },
    {
      name: "Services Australia",
      description:
        "Government agency providing various benefits and support payments. Helps you understand and access available government financial assistance.",
      link: "https://servicesaustralia.gov.au",
      logo: require("../assets/logos/Services Australia.png"),
    },
  ],
  "Crisis Situation": [
    {
      name: "Lifeline",
      description:
        "24/7 crisis support and suicide prevention services. Provides phone counseling and online chat support.",
      link: "https://www.lifeline.org.au",
      logo: require("../assets/logos/Lifeline.jpg"),
    },
    {
      name: "Kids Helpline",
      description:
        "24/7 free counseling service for young people aged 5-25. Offers phone, web, and email counseling.",
      link: "https://kidshelpline.com.au",
      logo: require("../assets/logos/Kids Helpline.png"),
    },
    {
      name: "Beyond Blue",
      description:
        "Provides information and support for depression and anxiety. Includes 24/7 phone support and online forums.",
      link: "https://www.beyondblue.org.au",
      logo: require("../assets/logos/Beyond Blue.png"),
    },
  ],
  "Domestic and Family Violence": [
    {
      name: "1800RESPECT",
      description:
        "National domestic violence and sexual assault counseling service. Provides 24/7 phone and online support.",
      link: "https://1800respect.org.au",
      logo: "https://1800respect.org.au/themes/custom/respect/logo.svg",
    },
    {
      name: "Another Closet",
      description:
        "LGBTIQ+ domestic violence support service. Provides specialized information and resources.",
      link: "https://www.anothercloset.com.au",
      logo: require("../assets/logos/Another Closet.jpg"),
    },
    {
      name: "No to Violence",
      description:
        "Men's family violence referral service. Helps men change violent behavior.",
      link: "https://ntv.org.au",
      logo: require("../assets/logos/No to Violence.png"),
    },
    {
      name: "QLife",
      description:
        "Counseling and referral service for LGBTIQ+ communities. Provides peer support and information.",
      link: "https://qlife.org.au",
      logo: require("../assets/logos/QLife.jpg"),
    },
  ],
  "Older Australian": [
    {
      name: "Aged Care Quality and Safety Commission",
      description:
        "Government agency overseeing aged care quality and safety. Handles complaints and monitors service standards.",
      link: "https://www.agedcarequality.gov.au",
      logo: require("../assets/logos/Aged Care Quality and Safety Commission.svg")
        .default,
    },
    {
      name: "National Seniors",
      description:
        "Australia's largest advocacy organization for older Australians. Provides member benefits and services.",
      link: "https://nationalseniors.com.au",
      logo: require("../assets/logos/National Seniors.jpg"),
    },
  ],
  Multicultural: [
    {
      name: "Australian Red Cross",
      description:
        "Support services for migrants and refugees. Helps newcomers adapt to life in Australia.",
      link: "https://www.redcross.org.au",
      logo: require("../assets/logos/Australian Red Cross.svg").default,
    },
    {
      name: "Refugee Advice and Casework Service",
      description:
        "Legal assistance for asylum seekers and refugees. Provides free legal advice and representation.",
      link: "https://www.racs.org.au",
      logo: require("../assets/logos/Refugee Advice and Casework Service.png"),
    },
  ],
  "Mental Health": [
    {
      name: "Lifeline",
      description:
        "24/7 crisis support and mental health services. Provides phone and online counseling support.",
      link: "https://www.lifeline.org.au",
      logo: require("../assets/logos/Lifeline.jpg"),
    },
    {
      name: "Mensline Australia",
      description:
        "24/7 counseling service specifically for men. Provides emotional support and mental health advice.",
      link: "https://mensline.org.au",
      logo: require("../assets/logos/Mensline Australia.png"),
    },
    {
      name: "Beyond Blue",
      description:
        "Depression and anxiety support services. Includes online forums and professional counseling.",
      link: "https://www.beyondblue.org.au",
      logo: require("../assets/logos/Beyond Blue.png"),
    },
  ],
  Disability: [
    {
      name: "National Disability Service",
      description:
        "Peak body for disability service providers in Australia. Provides service information and advocacy.",
      link: "https://www.nds.org.au",
      logo: require("../assets/logos/National Disability Service.png"),
    },
    {
      name: "National Disability Insurance Scheme",
      description:
        "Provides personalized support for people with disability. Helps access needed services and assistance.",
      link: "https://www.ndis.gov.au",
      logo: require("../assets/logos/National Disability Insurance Scheme.svg")
        .default,
    },
  ],
  Bereavement: [
    {
      name: "Grief Australia",
      description:
        "National grief counseling and support service. Helps people cope with loss of loved ones.",
      link: "https://www.grief.org.au",
      logo: require("../assets/logos/Grief Australia.jpg"),
    },
  ],
  Addiction: [
    {
      name: "Counselling Online",
      description:
        "24/7 online drug and alcohol counseling service. Provides free professional support and advice.",
      link: "https://www.counsellingonline.org.au",
      logo: require("../assets/logos/Counselling Online.jpg"),
    },
    {
      name: "Gambling Help Online",
      description:
        "24/7 gambling problem support service. Offers free counseling and self-help tools.",
      link: "https://www.gamblinghelponline.org.au",
      logo: require("../assets/logos/Gambling Help Online.png"),
    },
  ],
  "Serious Illness": [
    {
      name: "Palliative Care Australia",
      description:
        "Palliative care services and information. Supports patients and families in accessing quality end-of-life care.",
      link: "https://palliativecare.org.au",
      logo: require("../assets/logos/Palliative Care Australia.png"),
    },
    {
      name: "Health Direct",
      description:
        "Government-backed health information and advice service. Provides 24/7 health advice helpline.",
      link: "https://www.healthdirect.gov.au",
      logo: require("../assets/logos/Health Direct.svg").default,
    },
    {
      name: "Capacity Australia",
      description:
        "Promotes understanding and support for cognitive impairment. Provides educational resources and professional advice.",
      link: "https://capacityaustralia.org.au",
      logo: require("../assets/logos/Capacity Australia.png"),
    },
    {
      name: "Cancer Council",
      description:
        "Support for cancer patients and their families. Provides information, counseling, and practical help.",
      link: "https://www.cancer.org.au",
      logo: require("../assets/logos/Cancer Council.png"),
    },
    {
      name: "Dementia Australia",
      description:
        "Support for people with dementia and their carers. Provides education and service referrals.",
      link: "https://www.dementia.org.au",
      logo: require("../assets/logos/Dementia Australia.svg").default,
    },
  ],
  "Govt & Legal Service": [
    {
      name: "Community Legal Centres Australia",
      description:
        "Provides free legal advice and assistance. Helps vulnerable people access legal services.",
      link: "https://www.clcs.org.au",
      logo: require("../assets/logos/Community Legal Centres Australia.svg")
        .default,
    },
    {
      name: "National Disability Insurance Scheme",
      description:
        "National disability insurance plan. Provides personalized support for people with disability.",
      link: "https://www.ndis.gov.au",
      logo: require("../assets/logos/National Disability Insurance Scheme.svg")
        .default,
    },
    {
      name: "Services Australia",
      description:
        "Provides government benefits and service information. Helps people understand and access government support.",
      link: "https://www.servicesaustralia.gov.au",
      logo: require("../assets/logos/Services Australia.png"),
    },
    {
      name: "MoneySmart",
      description:
        "ASIC's financial education website. Provides financial advice and tools.",
      link: "https://moneysmart.gov.au",
      logo: require("../assets/logos/moneysmart.jpg"),
    },
  ],
  "Regulator, Legislation, Ombudsman": [
    {
      name: "ACCC",
      description:
        "Australian Competition and Consumer Commission. Protects consumer rights and promotes fair competition.",
      link: "https://www.accc.gov.au",
      logo: require("../assets/logos/ACCC.png"),
    },
    {
      name: "ACDBA",
      description:
        "Australian Collectors & Debt Buyers Association. Sets industry standards and best practices.",
      link: "https://www.acdba.com",
      logo: require("../assets/logos/ACDBA.png"),
    },
    {
      name: "ASIC",
      description:
        "Australian Securities and Investments Commission. Regulates financial services and markets.",
      link: "https://asic.gov.au",
      logo: require("../assets/logos/ASIC.svg").default,
    },
    {
      name: "OAIC",
      description:
        "Office of the Australian Information Commissioner. Protects privacy and information rights.",
      link: "https://www.oaic.gov.au",
      logo: require("../assets/logos/OAIC.png"),
    },
    {
      name: "AFCA",
      description:
        "Australian Financial Complaints Authority. Handles financial service complaints.",
      link: "https://www.afca.org.au",
      logo: require("../assets/logos/AFCA.svg").default,
    },
  ],
  "Credit Bureaus": [
    {
      name: "illion",
      description:
        "Provides credit reporting and scoring services. Helps individuals understand their credit status.",
      link: "https://www.illion.com.au",
      logo: require("../assets/logos/illion.svg").default,
    },
    {
      name: "Equifax",
      description:
        "Provides credit information and identity verification services. Helps manage personal credit records.",
      link: "https://www.equifax.com.au",
      logo: require("../assets/logos/Equifax.png"),
    },
    {
      name: "Experian",
      description:
        "Provides credit reporting and monitoring services. Helps prevent identity theft and fraud.",
      link: "https://www.experian.com.au",
      logo: require("../assets/logos/Experian.png"),
    },
  ],
};

const ExternalInfo = () => {
  const [selectedCategory, setSelectedCategory] = useState(
    "Financial Assistance"
  );

  return (
    <Container>
      <PageHeader>
        <h1>Help Resources</h1>
        <p>
          Find support services and information to help you through difficult
          times
        </p>
      </PageHeader>

      <CategorySection>
        <CategorySelect
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          {Object.keys(categories).map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </CategorySelect>
      </CategorySection>

      <CardGrid>
        {categories[selectedCategory].map((org) => (
          <Card key={org.name}>
            <LogoContainer>
              <img src={org.logo} alt={`${org.name} logo`} />
            </LogoContainer>
            <CardContent>
              <h3>{org.name}</h3>
              <p>{org.description}</p>
              <VisitButton
                href={org.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit Website
              </VisitButton>
            </CardContent>
          </Card>
        ))}
      </CardGrid>
    </Container>
  );
};

const PageHeader = styled.div`
  text-align: center;
  margin: 3rem 0;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #76b900;
  }

  p {
    font-size: 1.2rem;
    color: #000000;
  }
`;

const CategorySection = styled.div`
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
`;

const CategorySelect = styled.select`
  padding: 0.8rem 2.5rem 0.8rem 1.5rem;
  font-size: 1rem;
  border: 2px solid #76b900;
  border-radius: 25px;
  background: white;
  color: #000000;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  max-width: 400px;

  /* 添加下拉箭头 */
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(118, 185, 0, 0.2);
  }

  &:hover {
    border-color: #8ed100;
  }
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
`;

const Card = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(118, 185, 0, 0.1);
  }
`;

const LogoContainer = styled.div`
  height: 150px;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;

  img {
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
  }
`;

const CardContent = styled.div`
  padding: 1.5rem;

  h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #76b900;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5;
    color: #000000;
    margin-bottom: 1.5rem;
    min-height: 4rem;
  }
`;

const VisitButton = styled.a`
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: #76b900;
  color: white;
  text-decoration: none;
  border-radius: 25px;
  font-weight: 500;
  transition: background 0.3s ease;

  &:hover {
    background: #8ed100;
  }
`;

export default ExternalInfo;
