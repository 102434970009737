import styled from "styled-components";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <h3>Freedom Credit</h3>
          <p>Your trusted partner in financial solutions</p>
          <ContactInfo>
            <ContactItem>
              <span>📞</span> 1300 930 070
            </ContactItem>
            <ContactItem>
              <span>✉️</span> info@freedomcredit.com.au
            </ContactItem>
          </ContactInfo>
        </FooterSection>

        <FooterSection>
          <h4>Quick Links</h4>
          <FooterLink to="/">Home</FooterLink>
          <FooterLink to="/about">About Us</FooterLink>
          <FooterLink to="/careers">Careers</FooterLink>
          <FooterLink to="/contact">Contact</FooterLink>
          <FooterLink to="/resources">Resources</FooterLink>
        </FooterSection>

        <FooterSection>
          <h4>Support</h4>
          <FooterLink to="/financial-hardship">Financial Hardship</FooterLink>
          <FooterLink to="/feedback">Feedback</FooterLink>
          <FooterLink to="/authorised-representative">
            Authorised Representatives
          </FooterLink>
          <FooterLink to="/customer-authorisation">
            Third Party Authorisation
          </FooterLink>
        </FooterSection>

        <FooterSection>
          <h4>Legal</h4>
          <FooterLink as="a" href="#">
            Privacy Policy
          </FooterLink>
          <FooterLink as="a" href="#">
            Terms of Service
          </FooterLink>
          <FooterLink as="a" href="#">
            Complaints Policy
          </FooterLink>
          <FooterLink as="a" href="#">
            Credit Guide
          </FooterLink>
        </FooterSection>
      </FooterContent>

      <FooterBottom>
        <p>
          &copy; {new Date().getFullYear()} Freedom Credit. All rights reserved.
        </p>
        <SocialLinks>
          <SocialLink href="#" aria-label="LinkedIn">
            <span>LinkedIn</span>
          </SocialLink>
        </SocialLinks>
      </FooterBottom>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background-color: #f0f0f0;
  color: #333;
  padding: 4rem 0 1rem;
  margin-top: 4rem;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 3rem;
  padding: 0 2rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 2rem;
  }
`;

const FooterSection = styled.div`
  h3 {
    color: #76b900;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  h4 {
    color: #76b900;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  p {
    color: #555;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
`;

const ContactInfo = styled.div`
  margin-top: 1.5rem;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  color: #555;

  span {
    font-size: 1.2rem;
  }
`;

const FooterLink = styled(Link)`
  display: block;
  color: #555;
  text-decoration: none;
  margin-bottom: 0.8rem;
  transition: color 0.2s ease;

  &:hover {
    color: #76b900;
  }
`;

const FooterBottom = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 3rem;
  padding: 1.5rem 2rem;
  border-top: 1px solid #dcdcdc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #555;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
`;

const SocialLink = styled.a`
  color: #555;
  text-decoration: none;
  transition: color 0.2s ease;

  &:hover {
    color: #76b900;
  }
`;

export default Footer;
