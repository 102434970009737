import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  max-width: ${props => props.fluid ? '100%' : '1200px'};
  margin: 0 auto;
  padding: ${props => props.theme.container.padding.mobile};

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: ${props => props.theme.container.padding.tablet};
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    padding: ${props => props.theme.container.padding.desktop};
  }
`;

export default Container;
