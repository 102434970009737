import styled from "styled-components";
import Container from "../components/common/Container";
import visionImage from "../assets/images/vision.jpeg";
import { motion } from "framer-motion";
import {
  FaHandshake,
  FaUsers,
  FaClipboardCheck,
  FaStar,
  FaChartLine,
} from "react-icons/fa";
import heroImage from "../assets/images/cf180878-e59f-4b21-98df-c5afebb8e642_0.jpeg";
import { useScroll, useTransform } from "framer-motion";

const About = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
  };

  return (
    <Container>
      <AboutSection>
        <HeroSection
          as={motion.div}
          {...fadeIn}
          style={{
            backgroundPositionY: useTransform(
              useScroll().scrollY,
              [0, 450],
              ["0%", "50%"]
            ),
          }}
        >
          <h1>
            About <GradientText>Freedom Credit</GradientText>
          </h1>
          <SubHeading>
            Freedom Credit is an Australian owned and operated business
            dedicated to transforming financial solutions. We combine innovation
            with compassion to help customers overcome financial challenges,
            while maintaining strong relationships with all stakeholders and an
            unwavering commitment to excellence.
          </SubHeading>
          <Stats>
            <StatItem>
              <h3>10+</h3>
              <p>Years Experience</p>
            </StatItem>
            <StatItem>
              <h3>5000+</h3>
              <p>Clients Served</p>
            </StatItem>
            <StatItem>
              <h3>95%</h3>
              <p>Success Rate</p>
            </StatItem>
          </Stats>
        </HeroSection>

        <VisionMissionSection>
          <VisionMissionContent>
            <VisionMission>
              <VisionBox>
                <h2>Our Vision</h2>
                <p>
                  To provide better financial outcomes to our customers, through
                  flexible payment solutions.
                </p>
              </VisionBox>
              <MissionBox>
                <h2>Our Mission</h2>
                <p>
                  Be the most trusted and recognised partner to our clients,
                  customers, and stakeholders.
                </p>
              </MissionBox>
            </VisionMission>
          </VisionMissionContent>
        </VisionMissionSection>

        <ValuesSection>
          <h2>Our Core Values</h2>
          <ValuesGrid>
            <ValueCard
              as={motion.div}
              whileHover={{ y: -5 }}
              transition={{ duration: 0.3 }}
            >
              <IconCircle>
                <FaHandshake />
              </IconCircle>
              <h3>Collaboration</h3>
              <p>
                We work together to achieve the best results for our company,
                our customers and our clients.
              </p>
            </ValueCard>

            <ValueCard
              as={motion.div}
              whileHover={{ y: -5 }}
              transition={{ duration: 0.3 }}
            >
              <IconCircle>
                <FaUsers />
              </IconCircle>
              <h3>Customer Focus</h3>
              <p>
                We prioritise delivering sustainable and meaningful change to
                customers' financial situation and providing an excellent
                customer experience.
              </p>
            </ValueCard>

            <ValueCard
              as={motion.div}
              whileHover={{ y: -5 }}
              transition={{ duration: 0.3 }}
            >
              <IconCircle>
                <FaClipboardCheck />
              </IconCircle>
              <h3>Accountability</h3>
              <p>
                We are proud of how we conduct ourselves, the decisions we make
                and our commitment to ensure our actions match our words.
              </p>
            </ValueCard>

            <ValueCard
              as={motion.div}
              whileHover={{ y: -5 }}
              transition={{ duration: 0.3 }}
            >
              <IconCircle>
                <FaStar />
              </IconCircle>
              <h3>Excellence</h3>
              <p>
                We hold ourselves to the highest standards in everything we do
                to achieve excellent outcomes.
              </p>
            </ValueCard>

            <ValueCard
              as={motion.div}
              whileHover={{ y: -5 }}
              transition={{ duration: 0.3 }}
            >
              <IconCircle>
                <FaChartLine />
              </IconCircle>
              <h3>Continuous Improvement</h3>
              <p>
                We are open-minded and continuously pursue new ideas, methods,
                products, processes, and procedures that drive positive change
                and improvement within our company.
              </p>
            </ValueCard>
          </ValuesGrid>
        </ValuesSection>
      </AboutSection>
    </Container>
  );
};

const AboutSection = styled.div`
  padding: 4rem 0;

  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const HeroSection = styled(motion.div)`
  position: relative;
  padding: 8rem 0;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 120%;
  background-image: url(${heroImage});

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      rgba(255, 255, 255, 0.92),
      rgba(255, 255, 255, 0.85)
    );
  }

  > * {
    position: relative;
    z-index: 1;
  }

  h1 {
    text-align: center;
    margin-bottom: 2rem;
    color: #2c3e50;
    font-size: 3rem;
  }

  @media (max-width: 1024px) {
    background-size: cover;
    h1 {
      font-size: 2.5rem;
    }
  }

  @media (max-width: 768px) {
    padding: 6rem 0;
    h1 {
      font-size: 2rem;
    }
  }
`;

const VisionMissionSection = styled.div`
  position: relative;
  margin: 4rem 2rem;
  background: #f8f9fa;
  border-radius: 12px;
  overflow: hidden;

  @media (max-width: 768px) {
    margin: 3rem 1rem;
  }
`;

const VisionImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5));
`;

const VisionMissionContent = styled.div`
  padding: 3rem;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const VisionMission = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;

  @media (max-width: 1024px) {
    gap: 2rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const VisionBox = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  h2 {
    color: #76b900;
    margin-bottom: 1rem;
    font-size: 1.8rem;
  }

  p {
    color: #505965;
    line-height: 1.6;
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
    h2 {
      font-size: 1.5rem;
    }
  }
`;

const MissionBox = styled(VisionBox)``;

const ValuesSection = styled.section`
  margin: 6rem 0;
  text-align: center;
  padding: 0 2rem;

  h2 {
    margin-bottom: 3rem;
    font-size: 2.5rem;
    background: linear-gradient(120deg, #76b900, #5c8f00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 768px) {
    margin: 4rem 0;
    padding: 0 1rem;

    h2 {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }
`;

const ValuesGrid = styled.div`
  display: grid;
  max-width: 1400px;
  margin: 0 auto;
  gap: 2rem;

  /* 大屏幕：3-2布局 */
  @media (min-width: 1200px) {
    grid-template-columns: repeat(6, 1fr);

    /* 第一行三个卡片 */
    & > *:nth-child(1) {
      grid-column: 1 / 3;
    }
    & > *:nth-child(2) {
      grid-column: 3 / 5;
    }
    & > *:nth-child(3) {
      grid-column: 5 / 7;
    }

    /* 第二行两个卡片居中 */
    & > *:nth-child(4) {
      grid-column: 2 / 4;
    }
    & > *:nth-child(5) {
      grid-column: 4 / 6;
    }
  }

  /* 中等屏幕：2-2-1布局 */
  @media (min-width: 768px) and (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);

    /* 最后一个卡片居中 */
    & > *:last-child {
      grid-column: 1 / -1;
      max-width: 500px;
      justify-self: center;
    }
  }

  /* 小屏幕：单列布局 */
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 0 auto;
  }
`;

const ValueCard = styled(motion.div)`
  padding: 2.5rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h3 {
    color: #2c3e50;
    font-size: 1.4rem;
    margin: 1rem 0;
    font-weight: 600;
  }

  p {
    color: #505965;
    line-height: 1.8;
    margin: 0;
    font-size: 1rem;
    max-width: 90%;
  }

  @media (max-width: 768px) {
    padding: 1.5rem;

    h3 {
      font-size: 1.2rem;
    }

    p {
      font-size: 0.95rem;
      line-height: 1.6;
    }
  }
`;

const IconCircle = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: linear-gradient(120deg, #76b900, #5c8f00);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 10px rgba(118, 185, 0, 0.2);

  svg {
    color: white;
    font-size: 1.8rem;
  }

  @media (max-width: 768px) {
    width: 60px;
    height: 60px;

    svg {
      font-size: 1.5rem;
    }
  }
`;

const GradientText = styled.span`
  background: linear-gradient(120deg, #76b900, #5c8f00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const SubHeading = styled.p`
  font-size: 1.4rem;
  color: #666;
  margin: 1rem auto 3rem;
  max-width: 800px;
  padding: 0 2rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin: 1rem auto 2rem;
  }
`;

const Stats = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const StatItem = styled.div`
  text-align: center;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);

  h3 {
    font-size: 2.5rem;
    color: #76b900;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
    h3 {
      font-size: 2rem;
    }
  }
`;

export default About;
