import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const AuthorisedRepresentative = () => {
  return (
    <Container>
      <HeroSection>
        <h1>Authorised Representative Portal</h1>
        <p>
          Supporting Financial Counsellors, Solicitors, and Credit Repairers
        </p>
      </HeroSection>

      <ContentSection>
        <InfoBox>
          <h2>Professional Partnership</h2>
          <p>
            We recognise the importance of effective and timely communication
            with our customers' authorised representatives. Our dedicated portal
            streamlines the process of managing your client's accounts with us.
          </p>
          <Note>
            If you are a customer looking to authorise a third-party on your
            account, please visit our{" "}
            <StyledLink to="/customer-authorisation">
              Third Party Authorisation
            </StyledLink>{" "}
            page.
          </Note>
        </InfoBox>

        <ProcessSection>
          <h2>How It Works</h2>
          <StepGrid>
            <Step>
              <StepNumber>1</StepNumber>
              <StepContent>
                <h3>Submit Authority</h3>
                <p>
                  Upload your Letter of Authority through our secure submission
                  form below.
                </p>
              </StepContent>
            </Step>

            <Step>
              <StepNumber>2</StepNumber>
              <StepContent>
                <h3>Account Hold</h3>
                <p>
                  Upon receipt, we'll place the account on hold while we process
                  your authority.
                </p>
              </StepContent>
            </Step>

            <Step>
              <StepNumber>3</StepNumber>
              <StepContent>
                <h3>Status Update</h3>
                <p>
                  Receive immediate acknowledgment and a comprehensive account
                  status update.
                </p>
              </StepContent>
            </Step>
          </StepGrid>
        </ProcessSection>

        <ServicesSection>
          <h2>Our Commitment to Representatives</h2>
          <ServiceGrid>
            <ServiceCard>
              <IconWrapper>⚡</IconWrapper>
              <h3>Swift Processing</h3>
              <p>
                Immediate acknowledgment of submissions and prompt account
                status updates
              </p>
            </ServiceCard>
            <ServiceCard>
              <IconWrapper>🔒</IconWrapper>
              <h3>Secure Management</h3>
              <p>
                Dedicated secure portal for submitting and managing client
                authorities
              </p>
            </ServiceCard>
            <ServiceCard>
              <IconWrapper>📊</IconWrapper>
              <h3>Account Oversight</h3>
              <p>
                Comprehensive account information and status updates for
                effective management
              </p>
            </ServiceCard>
            <ServiceCard>
              <IconWrapper>🤝</IconWrapper>
              <h3>Professional Support</h3>
              <p>Dedicated support team for authorised representatives</p>
            </ServiceCard>
          </ServiceGrid>
        </ServicesSection>

        <RequirementsBox>
          <h2>Required Documentation</h2>
          <RequirementsList>
            <li>Valid Letter of Authority on company letterhead</li>
            <li>Clear specification of authorised powers and limitations</li>
            <li>Client's full name and account details</li>
            <li>Representative's contact information</li>
            <li>Relevant professional credentials or registrations</li>
          </RequirementsList>
        </RequirementsBox>

        <ActionSection>
          <h2>Ready to Submit?</h2>
          <p>
            Upload your Letter of Authority below. Our team will review and
            process your submission promptly.
          </p>
          <ActionButton href="#" target="_blank" rel="noopener noreferrer">
            Submit Authority
          </ActionButton>
        </ActionSection>
      </ContentSection>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const HeroSection = styled.div`
  background-image: url("../assets/authorised-representative-hero.jpg");
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 20px;
  margin-bottom: 50px;
  border-radius: 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }

  h1,
  p {
    position: relative;
    z-index: 1;
  }

  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.5rem;
    opacity: 0.9;
  }
`;

const ContentSection = styled.div`
  padding: 40px 0;
`;

const InfoBox = styled.div`
  background-color: #f5f5f5;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 40px;

  h2 {
    color: #76b900;
    margin-bottom: 20px;
  }

  p {
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
`;

const Note = styled.div`
  background: #fff3cd;
  border-left: 5px solid #ffc107;
  padding: 15px;
  border-radius: 5px;
  color: #856404;
`;

const ProcessSection = styled.div`
  margin: 60px 0;

  h2 {
    text-align: center;
    color: #76b900;
    margin-bottom: 40px;
  }
`;

const StepGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 30px;
`;

const Step = styled.div`
  display: flex;
  align-items: flex-start;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const StepNumber = styled.div`
  background: #76b900;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 20px;
  flex-shrink: 0;
`;

const StepContent = styled.div`
  h3 {
    color: #333;
    margin-bottom: 10px;
  }

  p {
    color: #666;
    line-height: 1.5;
  }
`;

const ServicesSection = styled.div`
  margin: 60px 0;

  h2 {
    text-align: center;
    color: #76b900;
    margin-bottom: 40px;
  }
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
`;

const ServiceCard = styled.div`
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;

  h3 {
    color: #333;
    margin: 15px 0;
  }

  p {
    color: #666;
    line-height: 1.5;
  }
`;

const IconWrapper = styled.div`
  font-size: 2rem;
  margin-bottom: 15px;
`;

const RequirementsBox = styled.div`
  margin: 40px 0;
  padding: 30px;
  background: #f5f5f5;
  border-radius: 10px;
  border-left: 5px solid #76b900;

  h2 {
    color: #76b900;
    margin-bottom: 20px;
  }
`;

const RequirementsList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    margin: 15px 0;
    padding-left: 35px;
    position: relative;

    &:before {
      content: "✓";
      color: #76b900;
      position: absolute;
      left: 0;
      width: 20px;
      height: 20px;
      border: 2px solid #76b900;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 1;
    }
  }
`;

const ActionSection = styled.div`
  text-align: center;
  margin: 60px 0;
  padding: 40px;
  background: #f9f9f9;
  border-radius: 10px;

  h2 {
    color: #76b900;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 30px;
  }
`;

const ActionButton = styled.a`
  display: inline-block;
  background: #76b900;
  color: white;
  padding: 15px 40px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  transition: background 0.3s ease;

  &:hover {
    background: #648e00;
  }
`;

const StyledLink = styled(Link)`
  color: #76b900;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

export default AuthorisedRepresentative;
