import styled from 'styled-components';
import Container from '../components/common/Container';
import Button from '../components/common/Button';
import { useState } from 'react';

const Contact = () => {
  const [openFaq, setOpenFaq] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
  };

  const faqs = [
    {
      question: "What financial services do you offer?",
      answer: "We offer a comprehensive range of financial services including payment plans, financial advisory, and investment planning tailored to your needs."
    },
    {
      question: "How can I schedule a consultation?",
      answer: "You can schedule a consultation by filling out our contact form, calling us directly, or sending us an email. We'll respond within 24 hours."
    },
    {
      question: "Are your services available internationally?",
      answer: "Yes, we provide services to clients worldwide through our digital platform and remote consultation options."
    },
    {
      question: "What are your service fees?",
      answer: "Our fees vary based on the services required. We provide transparent pricing during the initial consultation."
    }
  ];

  return (
    <>
      <HeroSection>
        <Container>
          <HeroContent>
            <h1>Get in Touch</h1>
            <p>We're here to help with your financial journey</p>
          </HeroContent>
        </Container>
      </HeroSection>

      <Container>
        <MainContent>
          <FAQSection>
            <SectionTitle>Common Questions</SectionTitle>
            <FAQGrid>
              {faqs.map((faq, index) => (
                <FAQItem key={index}>
                  <FAQQuestion 
                    onClick={() => setOpenFaq(openFaq === index ? null : index)}
                    isOpen={openFaq === index}
                  >
                    {faq.question}
                    <FAQIcon isOpen={openFaq === index}>+</FAQIcon>
                  </FAQQuestion>
                  <FAQAnswer isOpen={openFaq === index}>
                    {faq.answer}
                  </FAQAnswer>
                </FAQItem>
              ))}
            </FAQGrid>
          </FAQSection>

          <ContactWrapper>
            <FormSection>
              <SectionTitle>Still have questions?</SectionTitle>
              <SubTitle>Send us a message and we'll get back to you</SubTitle>
              <ContactForm onSubmit={handleSubmit}>
                <FormGrid>
                  <FormGroup>
                    <label htmlFor="name">Full Name</label>
                    <input type="text" id="name" placeholder="John Doe" required />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="email">Email Address</label>
                    <input type="email" id="email" placeholder="john@example.com" required />
                  </FormGroup>
                </FormGrid>
                <FormGroup>
                  <label htmlFor="subject">Subject</label>
                  <input type="text" id="subject" placeholder="How can we help?" required />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="message">Message</label>
                  <textarea id="message" rows="5" placeholder="Tell us more about your needs..." required></textarea>
                </FormGroup>
                <SubmitButton type="submit">Send Message</SubmitButton>
              </ContactForm>
            </FormSection>

            <InfoSection>
              <SectionTitle>Contact Information</SectionTitle>
              <InfoGrid>
                <InfoCard>
                  <IconWrapper>📍</IconWrapper>
                  <div>
                    <h3>Visit Us</h3>
                    <p>123 Business St</p>
                    <p>City, ST 12345</p>
                  </div>
                </InfoCard>
                <InfoCard>
                  <IconWrapper>📞</IconWrapper>
                  <div>
                    <h3>Call Us</h3>
                    <p>+1 (555) 123-4567</p>
                    <p>Mon-Fri, 9AM-5PM</p>
                  </div>
                </InfoCard>
                <InfoCard>
                  <IconWrapper>✉️</IconWrapper>
                  <div>
                    <h3>Email Us</h3>
                    <p>info@freedom-credit.com</p>
                    <p>support@freedom-credit.com</p>
                  </div>
                </InfoCard>
              </InfoGrid>
            </InfoSection>
          </ContactWrapper>
        </MainContent>
      </Container>
    </>
  );
};

const HeroSection = styled.section`
  background: linear-gradient(135deg, ${props => props.theme.colors.primary}, ${props => props.theme.colors.secondary});
  color: white;
  padding: 4rem 0;
  margin-bottom: 4rem;
`;

const HeroContent = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;

  h1 {
    font-size: clamp(2rem, 5vw, 3.5rem);
    margin-bottom: 1rem;
  }

  p {
    font-size: clamp(1rem, 2vw, 1.2rem);
    opacity: 0.9;
  }
`;

const MainContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const ContactWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 4rem;
  margin-bottom: 6rem;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const SectionTitle = styled.h2`
  font-size: clamp(1.5rem, 3vw, 2rem);
  margin-bottom: 2rem;
  color: ${props => props.theme.colors.primary};
`;

const FormSection = styled.section`
  padding: 4rem 0;
  
  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin-top: 3rem;
  
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  
  label {
    font-weight: bold;
  }
  
  input, textarea {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const InfoCard = styled.div`
  padding: 1.5rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
  h3 {
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.primary};
  }
`;

const FAQSection = styled.section`
  margin-bottom: 6rem;
  background: ${props => props.theme.colors.background};
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.05);
`;

const FAQGrid = styled.div`
  display: grid;
  gap: 1rem;
  max-width: 900px;
  margin: 0 auto;
`;

const FAQItem = styled.div`
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  
  &:hover {
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
`;

const FAQQuestion = styled.button`
  width: 100%;
  padding: 1.5rem;
  background: ${props => props.isOpen ? props.theme.colors.primary : 'white'};
  color: ${props => props.isOpen ? 'white' : props.theme.colors.text};
  border: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.1rem;
  transition: all 0.3s ease;

  &:hover {
    background: ${props => props.isOpen ? 
      props.theme.colors.primary : 
      props.theme.colors.background};
  }
`;

const FAQIcon = styled.span`
  transform: rotate(${props => props.isOpen ? '45deg' : '0'});
  transition: transform 0.3s ease;
  font-size: 1.5rem;
  line-height: 1;
`;

const FAQAnswer = styled.div`
  padding: ${props => props.isOpen ? '1rem' : '0 1rem'};
  max-height: ${props => props.isOpen ? '200px' : '0'};
  opacity: ${props => props.isOpen ? '1' : '0'};
  transition: all 0.3s ease;
  overflow: hidden;
  background: white;
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
`;

const SubmitButton = styled.button`
  padding: 0.75rem 1rem;
  background: ${props => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: ${props => props.theme.colors.primaryDark};
  }
`;

const InfoSection = styled.section`
  padding: 4rem 0;
  
  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
`;

const IconWrapper = styled.span`
  font-size: 1.5rem;
  margin-right: 0.5rem;
`;

const SubTitle = styled.p`
  color: ${props => props.theme.colors.text};
  font-size: 1.1rem;
  margin-bottom: 2rem;
  text-align: center;
`;

export default Contact;
