import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CustomerAuthorisation = () => {
  return (
    <Container>
      <HeroSection>
        <h1>Third-Party Authorisation</h1>
        <p>Authorise someone you trust to help manage your account</p>
      </HeroSection>

      <ContentSection>
        <InfoBox>
          <h2>Authorising a Third Party</h2>
          <p>
            We understand that sometimes you may need someone else to help
            manage your account. Whether it's a family member or a trusted
            friend, we're here to help make that process simple and secure.
          </p>
          <Note>
            <h3>Are you a Professional Representative?</h3>
            <p>
              If you are a Financial Counsellor, Solicitor, Credit Repairer, or
              other professional representative, please visit our{" "}
              <StyledLink to="/authorised-representative">
                Authorised Representative Portal
              </StyledLink>
              .
            </p>
          </Note>
        </InfoBox>

        <ProcessSection>
          <h2>How to Authorise</h2>
          <StepGrid>
            <Step>
              <StepNumber>1</StepNumber>
              <StepContent>
                <h3>Complete Form</h3>
                <p>
                  Fill in all required details in the authorisation form below,
                  including your current contact information.
                </p>
              </StepContent>
            </Step>

            <Step>
              <StepNumber>2</StepNumber>
              <StepContent>
                <h3>Verification</h3>
                <p>
                  We'll verify your details against our records to ensure the
                  security of your account.
                </p>
              </StepContent>
            </Step>

            <Step>
              <StepNumber>3</StepNumber>
              <StepContent>
                <h3>Confirmation</h3>
                <p>
                  Once verified, we'll process your request and send
                  confirmation to both you and your authorised person.
                </p>
              </StepContent>
            </Step>
          </StepGrid>
        </ProcessSection>

        <ImportantInfoSection>
          <h2>Important Information</h2>
          <InfoGrid>
            <InfoCard>
              <IconWrapper>🔐</IconWrapper>
              <h3>Security First</h3>
              <p>
                We take your privacy seriously. Your authorised person will need
                to verify their identity when contacting us.
              </p>
            </InfoCard>
            <InfoCard>
              <IconWrapper>⏱️</IconWrapper>
              <h3>Duration</h3>
              <p>
                You can specify how long you want the authorisation to remain
                active, and can cancel it at any time.
              </p>
            </InfoCard>
            <InfoCard>
              <IconWrapper>📋</IconWrapper>
              <h3>Access Level</h3>
              <p>
                Choose what level of access and authority you want to grant to
                your third party.
              </p>
            </InfoCard>
          </InfoGrid>
        </ImportantInfoSection>

        <RequirementsBox>
          <h2>What You'll Need</h2>
          <RequirementsList>
            <li>Your current account details and contact information</li>
            <li>Third party's full name and contact details</li>
            <li>Specification of authority level to be granted</li>
            <li>Preferred duration of the authorisation</li>
            <li>Valid identification for both parties</li>
          </RequirementsList>
        </RequirementsBox>

        <TipBox>
          <h3>Important Reminder</h3>
          <p>
            Please ensure your contact details match those on your account to
            avoid any delays in processing. If your details have changed, we may
            need to contact you for verification.
          </p>
        </TipBox>

        <ActionSection>
          <h2>Ready to Proceed?</h2>
          <p>
            Complete the form below to authorise a third party on your account.
            All fields marked with an asterisk (*) are required.
          </p>
          <ActionButton href="#" target="_blank" rel="noopener noreferrer">
            Start Authorisation
          </ActionButton>
        </ActionSection>
      </ContentSection>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const HeroSection = styled.div`
  background-image: url("../assets/customer-authorisation-hero.jpg");
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 20px;
  margin-bottom: 50px;
  border-radius: 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }

  h1,
  p {
    position: relative;
    z-index: 1;
  }

  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.5rem;
    opacity: 0.9;
  }
`;

const ContentSection = styled.div`
  padding: 40px 0;
`;

const InfoBox = styled.div`
  background-color: #f5f5f5;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 40px;

  h2 {
    color: #76b900;
    margin-bottom: 20px;
  }

  p {
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
`;

const Note = styled.div`
  background: #fff3cd;
  border-left: 5px solid #ffc107;
  padding: 15px;
  border-radius: 5px;
  color: #856404;

  h3 {
    margin-bottom: 10px;
    color: #856404;
  }

  p {
    margin-bottom: 0;
  }
`;

const StyledLink = styled(Link)`
  color: #76b900;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

const ProcessSection = styled.div`
  margin: 60px 0;

  h2 {
    text-align: center;
    color: #76b900;
    margin-bottom: 40px;
  }
`;

const StepGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 30px;
`;

const Step = styled.div`
  display: flex;
  align-items: flex-start;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const StepNumber = styled.div`
  background: #76b900;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 20px;
  flex-shrink: 0;
`;

const StepContent = styled.div`
  h3 {
    color: #333;
    margin-bottom: 10px;
  }

  p {
    color: #666;
    line-height: 1.5;
  }
`;

const ImportantInfoSection = styled.div`
  margin: 60px 0;

  h2 {
    text-align: center;
    color: #76b900;
    margin-bottom: 40px;
  }
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
`;

const InfoCard = styled.div`
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;

  h3 {
    color: #333;
    margin: 15px 0;
  }

  p {
    color: #666;
    line-height: 1.5;
  }
`;

const IconWrapper = styled.div`
  font-size: 2rem;
  margin-bottom: 15px;
`;

const RequirementsBox = styled.div`
  margin: 40px 0;
  padding: 30px;
  background: #f5f5f5;
  border-radius: 10px;
  border-left: 5px solid #76b900;

  h2 {
    color: #76b900;
    margin-bottom: 20px;
  }
`;

const RequirementsList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    margin: 15px 0;
    padding-left: 35px;
    position: relative;

    &:before {
      content: "✓";
      color: #76b900;
      position: absolute;
      left: 0;
      width: 20px;
      height: 20px;
      border: 2px solid #76b900;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 1;
    }
  }
`;

const TipBox = styled.div`
  margin: 40px 0;
  padding: 20px;
  background: #fff3cd;
  border-radius: 10px;
  border-left: 5px solid #ffc107;

  h3 {
    color: #856404;
    margin-bottom: 10px;
  }

  p {
    color: #856404;
    line-height: 1.5;
  }
`;

const ActionSection = styled.div`
  text-align: center;
  margin: 60px 0;
  padding: 40px;
  background: #f9f9f9;
  border-radius: 10px;

  h2 {
    color: #76b900;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 30px;
  }
`;

const ActionButton = styled.a`
  display: inline-block;
  background: #76b900;
  color: white;
  padding: 15px 40px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  transition: background 0.3s ease;

  &:hover {
    background: #648e00;
  }
`;

export default CustomerAuthorisation;
