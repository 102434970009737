import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeAll = () => {
    setIsOpen(false);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return (
    <Nav>
      <NavContainer>
        <Logo to="/" onClick={closeAll}>
          Freedom Credit
        </Logo>

        <MenuButton onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
          <span></span>
          <span></span>
          <span></span>
        </MenuButton>

        <Overlay isOpen={isOpen} onClick={closeAll} />
        <NavLinks isOpen={isOpen}>
          <NavLink to="/about" onClick={closeAll}>
            About
          </NavLink>
          <NavLink to="/careers" onClick={closeAll}>
            Careers
          </NavLink>
          <DropdownContainer>
            <DropdownButton onClick={handleDropdownClick}>
              Self Service <Arrow isOpen={isDropdownOpen}>▼</Arrow>
            </DropdownButton>
            <DropdownContent isOpen={isDropdownOpen}>
              <DropdownLink to="/feedback" onClick={closeAll}>
                Feedback
              </DropdownLink>
              <DropdownLink to="/financial-hardship" onClick={closeAll}>
                Financial Hardship
              </DropdownLink>
              <DropdownLink to="/authorised-representative" onClick={closeAll}>
                Authorised Representative
              </DropdownLink>
              <DropdownLink to="/customer-authorisation" onClick={closeAll}>
                Customer Authorisation
              </DropdownLink>
            </DropdownContent>
          </DropdownContainer>

          <NavLink to="/resources" onClick={closeAll}>
            Resources
          </NavLink>

          <NavLink to="/contact" onClick={closeAll}>
            Contact
          </NavLink>
        </NavLinks>
      </NavContainer>
    </Nav>
  );
};

const Nav = styled.nav`
  background: white;
  box-shadow: 0 2px 8px rgba(118, 185, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const NavContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const Logo = styled(Link)`
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  color: #76b900;
  transition: color 0.2s;

  &:hover {
    color: #648e00;
  }
`;

const MenuButton = styled.button`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 200;

  span {
    width: 2rem;
    height: 0.25rem;
    background: #76b900;
    border-radius: 10px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    transform-origin: 1px;
  }

  ${({ isOpen }) => isOpen && `
    span:first-child {
      transform: rotate(45deg);
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }
  `}

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    position: fixed;
    right: -100%;
    top: 0;
    height: 100vh;
    width: 250px;
    background: white;
    padding: 5rem 1rem 1rem;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
    transition: right 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 100;

    ${({ isOpen }) => isOpen && `
      right: 0;
    `}
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #333;
  font-weight: 500;
  transition: all 0.2s;
  padding: 0.5rem 0.8rem;
  border-radius: 25px;

  &:hover {
    color: #76b900;
    background: rgba(118, 185, 0, 0.1);
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

const DropdownButton = styled.button`
  background: none;
  border: none;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  padding: 0.5rem 0.8rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 25px;
  transition: all 0.2s;

  &:hover {
    color: #76b900;
    background: rgba(118, 185, 0, 0.1);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
    justify-content: center;
    padding: 1rem;
  }
`;

const Arrow = styled.span`
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0)")};
  font-size: 0.8rem;
`;

const DropdownContent = styled.div`
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 50%;
  transform: ${({ isOpen }) =>
    isOpen ? "translateX(-50%) scaleY(1)" : "translateX(-50%) scaleY(0)"};
  transform-origin: top;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background: white;
  min-width: 220px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 0.5rem;
  z-index: 1000;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  pointer-events: ${({ isOpen }) => (isOpen ? "all" : "none")};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    position: static;
    transform: scaleY(${({ isOpen }) => (isOpen ? 1 : 0)});
    transform-origin: top;
    max-height: ${({ isOpen }) => (isOpen ? "500px" : "0")};
    box-shadow: none;
    min-width: 100%;
    background: rgba(118, 185, 0, 0.05);
    border-radius: 0;
    opacity: 1;
    margin: 0;
    padding: ${({ isOpen }) => (isOpen ? "0.5rem 0" : "0")};
    visibility: visible;
    pointer-events: all;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
  }
`;

const DropdownLink = styled(Link)`
  display: block;
  padding: 0.8rem 1.2rem;
  text-decoration: none;
  color: #333;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
  border-radius: 6px;
  margin: 0.2rem;
  opacity: 1;
  transform: translateY(0);

  &:hover {
    background: #76b900;
    color: white;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 0.8rem 1rem;
    margin: 0;
    border-radius: 0;
    transform: translateY(${({ isOpen }) => (isOpen ? "0" : "-10px")});

    &:hover {
      background: rgba(118, 185, 0, 0.15);
      color: #76b900;
    }
  }
`;

const Overlay = styled.div`
  display: none;
  
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 90;
  }
`;

export default Navbar;
